import clsx from 'clsx';
import { MarkdownWithBulletProps } from 'interfaces/cms/content';

export default function MarkdownWithBullet({
  bulletColor,
  subtitle,
  align,
  subtitleColor,
  cssClass = '',
}: MarkdownWithBulletProps) {
  return (
    <div
      className={clsx(
        'heart-bullet',
        bulletColor ?? bulletColor,
        cssClass ?? cssClass
      )}
    >
      <div
        dangerouslySetInnerHTML={{ __html: subtitle }}
        style={{ color: subtitleColor, textAlign: align }}
      />
    </div>
  );
}
